/* Forms */
.MuiInputAdornment-root .MuiIconButton-root:hover {
  background-color: inherit;
}

/* Data Grid */

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-root .cell-website,
.MuiDataGrid-root .cell-subscribers,
.MuiDataGrid-root .cell-views,
.MuiDataGrid-root .cell-author {
  font-size: 0.8rem;
}

.MuiDataGrid-root .cell-users {
  font-size: 1.2rem;
}

.MuiDataGrid-root .cell-headline {
  font-size: 0.75rem;
  white-space: normal !important;
}

.MuiDataGrid-root .cell-headline .MuiDataGrid-cellContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 4px 10px !important;
}

@media (min-width: 600px) {
  .MuiDataGrid-root .cell-headline {
    font-size: 1rem;
    font-weight: 600;
  }
}

/* Animations */

@keyframes ping {
  75%,
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

/* Fullscreen Mode */

.MuiPaper-root.is-fullscreen {
  position: fixed;
  inset: 0px;
  z-index: 1200;
}

@media (min-width: 1536px) {
  .MuiPaper-root.is-fullscreen .MuiTypography-h1 {
    font-size: 3rem;
  }

  .MuiPaper-root.is-fullscreen .MuiTypography-h2 {
    font-size: 2rem;
  }

  .MuiPaper-root.is-fullscreen .MuiTypography-subtitle1 {
    font-size: 1.3rem;
  }

  .MuiPaper-root.is-fullscreen .MuiTypography-subtitle2 {
    font-size: 1.1rem;
  }

  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .MuiDataGrid-row,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .MuiDataGrid-cell {
    max-height: none !important;
  }

  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-users,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-subscribers,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-views {
    font-size: 1.2rem;
  }

  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-website,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-section,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-author,
  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-type {
    font-size: 1rem;
  }

  .MuiPaper-root.is-fullscreen .MuiDataGrid-root .cell-headline {
    font-size: 1.5rem;
    white-space: nowrap !important;
  }

  .MuiPaper-root.is-fullscreen .referrals-chart {
    padding-left: 150px;
  }
}
